import React, { ChangeEvent } from 'react';
import { Container } from '../../styles';
import { Col, Row } from 'react-bootstrap';
import Separator from '~/components/Separator';
import InputRadioButton from '~/components/Inputs/InputRadioButton';
import {
  InputCidade,
  InputDate,
  InputMaskCelular,
  InputMaskCep,
  InputMaskCnpj,
  InputMaskCpf,
  InputMaskTelefone,
  InputSelect,
  InputText,
  InputText2,
} from '~/components/NovosInputs';
import ToggleDefault from '~/components/ToggleDefault';
import Button from '@atlaskit/button';
import { AiOutlineGlobal, AiOutlineSearch } from 'react-icons/ai';
import { CircularProgress } from '@material-ui/core';
import { useFornecedorContext } from '../../hooks/useFornecedorContext';
import { Default, TiposDePessoa } from '../../data';
import { FaWhatsapp } from 'react-icons/fa';

export const AbaGeral: React.FC = () => {
  const {
    register,
    control,
    setValue,
    watch,
    formState: { errors },
    tipoPessoa,
    isUpdate,
    changeTipoPessoa,
    init,
    loadingIcon,
    setNameFornecedor,
    searchPessoa,
    searchEmpresa,
    flagInativo,
    setFlagInativo,
    handleCursorPosition,
    openWhatsApp,
    inputSite,
    setinputSite,
    openSite,
    searchCep,
    inputCidadeRef,
    clearCampoCidade,
    cidade,
    setCidadeSelected,
  } = useFornecedorContext();

  const watchSexo = watch('sexo');

  return (
    <Container>
      <Row>
        <Col sm={12}>
          <Separator labelText="Dados Pessoais" />
        </Col>
        <Col
          className="containerTipoPessoa"
          sm={12}
          md={3}
          style={{ marginTop: '-5px' }}
        >
          <InputRadioButton
            name="tiposDePessoa"
            value={tipoPessoa}
            label="Tipo de Pessoa"
            options={TiposDePessoa}
            isDisabled={isUpdate}
            onChange={(val: string | number | undefined) => {
              changeTipoPessoa(Number(val));
              setValue('tipoPessoa', Number(val));
            }}
          />
        </Col>
        <Col className="containerDocument" sm={12} md={3}>
          <div className="input-container">
            {tipoPessoa === 0 ? (
              <InputMaskCpf
                label="CPF"
                name="num_cpf"
                hasButton
                clickedButton={searchPessoa}
                loadingIcon={loadingIcon === 'buscaPessoa'}
                isError={init && !!errors.num_cpf}
                control={control}
                register={register}
              />
            ) : (
              <InputMaskCnpj
                label="CNPJ"
                name="num_cnpj"
                hasButton
                clickedButton={searchEmpresa}
                loadingIcon={loadingIcon === 'buscaPessoa'}
                isError={init && !!errors.num_cnpj}
                control={control}
              />
            )}
          </div>
        </Col>
        <Col sm={12} md={2}>
          <InputText
            label="IE"
            maxLength={100}
            placeholder="Inscrição Estadual"
            name="num_ie"
            toLowerCase={false}
            register={register}
            disabled={false}
            isError={init && !!errors.num_ie}
          />
        </Col>
        {tipoPessoa === 0 && (
          <Col sm={12} md={2}>
            <InputSelect
              label="Sexo"
              value={watchSexo?.label || ''}
              placeholder="Selecione"
              name="sexo"
              register={register}
              isError={init && !!errors.sexo}
              control={control}
              options={Default.sexo}
              changeSelected={(selected) => setValue('sexo', selected)}
            />
          </Col>
        )}
        <Col sm={12} md={2}>
          <ToggleDefault
            labelText="Inativo?"
            setChecked={flagInativo}
            onSwitch={() => {
              setValue('inativo', !flagInativo);
              setFlagInativo(!flagInativo);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <InputText2
            label={tipoPessoa === 0 ? 'Nome' : 'Razão social'}
            maxLength={100}
            placeholder={
              tipoPessoa === 0 ? 'Informe o Nome' : 'Informe a Razão social'
            }
            name="name"
            register={register}
            disabled={false}
            isError={init && !!errors.name}
            control={control}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              // const regex = new RegExp(/[^a-zA-Z\s]+$/g);
              // if (!regex.test(event.target.value)) {
              const cursorPosition = handleCursorPosition(event);
              setTimeout(() => {
                if (document.activeElement === event.target) {
                  event.target.setSelectionRange(
                    cursorPosition,
                    cursorPosition,
                  );
                }
              }, 0);

              setNameFornecedor(event.target.value.toUpperCase());
              setValue('name', event.target.value.toUpperCase());
              // }
            }}
          />
        </Col>
        <Col sm={12} md={4}>
          <InputText
            label={tipoPessoa === 0 ? 'Apelido' : 'Nome fantasia'}
            maxLength={50}
            placeholder={
              tipoPessoa === 0 ? 'Informe o Apelido' : 'Informe o Nome fantasia'
            }
            name="apelido"
            register={register}
            disabled={false}
            toLowerCase={false}
            isError={!!errors.apelido}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputDate
            label={tipoPessoa === 0 ? 'Nascimento' : 'Abertura'}
            placeholder=""
            name="nascimentoAbertura"
            register={register}
            isError={!!errors.nascimentoAbertura}
            control={control}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Separator labelText="Contato" />
        </Col>
        <Col sm={12} md={2}>
          <InputMaskTelefone
            label="Telefone"
            name="telefone"
            isError={!!errors.telefone}
            control={control}
          />
        </Col>
        <Col sm={12} md={2}>
          <div className="input-container">
            <InputMaskCelular
              label="Celular"
              name="celular"
              isError={!!errors.celular}
              control={control}
            />
            <Button type="button" className="min-button" onClick={openWhatsApp}>
              <FaWhatsapp color="#fff" style={{ marginTop: '-2px' }} />
            </Button>
          </div>
        </Col>
        <Col sm={12} md={8}>
          <InputText2
            label="Contato"
            maxLength={50}
            placeholder="Informe o Contato"
            name="contato"
            register={register}
            disabled={false}
            isError={!!errors.contato}
            control={control}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const regex = new RegExp(/[^a-zA-Z\s]+$/g);
              if (!regex.test(event.target.value)) {
                const cursorPosition = handleCursorPosition(event);
                setTimeout(() => {
                  if (document.activeElement === event.target) {
                    event.target.setSelectionRange(
                      cursorPosition,
                      cursorPosition,
                    );
                  }
                }, 0);

                setValue('contato', event.target.value.toUpperCase());
              }
            }}
          />
        </Col>
        <Col sm={12} md={6}>
          <InputText
            label="E-mail"
            maxLength={100}
            placeholder="Informe o E-mail"
            name="email"
            register={register}
            disabled={false}
            isError={!!errors.email}
            toLowerCase
          />
        </Col>
        <Col sm={12} md={6}>
          <div className="input-container">
            <InputText
              label="Site"
              maxLength={150}
              placeholder="Informe a página WEB"
              name="site"
              register={register}
              disabled={false}
              isError={!!errors.site}
              onChange={(e: any) => {
                setinputSite(e.target.value);
              }}
            />
            <Button
              type="button"
              className="min-button"
              onClick={() => {
                openSite(inputSite);
              }}
            >
              <AiOutlineGlobal color="#fff" style={{ marginTop: '-2px' }} />
            </Button>
          </div>
        </Col>
        <Col sm={12}>
          <Separator labelText="Endereço" />
        </Col>
        <Col sm={12} md={2}>
          <div className="input-container">
            <InputMaskCep
              label="CEP"
              name="cep"
              isError={init && !!errors.cep}
              control={control}
            />
            <Button type="button" className="min-button" onClick={searchCep}>
              {loadingIcon === 'cep' ? (
                <CircularProgress style={{ color: '#fff' }} size={15} />
              ) : (
                <AiOutlineSearch color="#fff" />
              )}
            </Button>
          </div>
        </Col>
        <Col sm={12} md={5}>
          <InputText
            label="Logradouro"
            maxLength={100}
            placeholder="Informe o Logradouro"
            name="logradouro"
            register={register}
            toLowerCase={false}
            disabled={false}
            isError={init && !!errors.logradouro}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputText
            label="Número"
            maxLength={15}
            placeholder="Informe o Número"
            name="numero"
            register={register}
            disabled={false}
            isError={init && !!errors.numero}
          />
        </Col>
        <Col sm={12} md={3}>
          <InputText
            label="Bairro"
            maxLength={100}
            placeholder="Informe o Bairro"
            name="bairro"
            toLowerCase={false}
            register={register}
            disabled={false}
            isError={init && !!errors.bairro}
          />
        </Col>
        <Col sm={12} md={8}>
          <InputText
            label="Complemento"
            maxLength={50}
            placeholder="Informe o Complemento"
            name="complemento"
            toLowerCase={false}
            register={register}
            disabled={false}
            isError={!!errors.complemento}
          />
        </Col>
        <Col sm={12} md={4} ref={inputCidadeRef}>
          <InputCidade
            label="Cidade"
            maxLength={29}
            setValue={setValue}
            placeholder="Selecione"
            clearCampo={clearCampoCidade}
            cidadeABuscar={cidade}
            name="cidades"
            register={register}
            isError={!!errors.cidades}
            control={control}
            changeSelected={(selected) => {
              setValue('cidades', selected);
              setCidadeSelected(selected);
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};
