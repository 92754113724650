import React, { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { InputSelect } from '~/components/NovosInputs';
import Separator from '~/components/Separator';
import InputNumberDias from '~/components/Inputs/inputNumberDias';
import { CancelButton, ConfirmButton } from '~/components/Buttons';
import { useFornecedorContext } from '../../hooks/useFornecedorContext';
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import { ButtonRow } from '../../styles';
import { GoPencil } from 'react-icons/go';
import { MdDeleteForever } from 'react-icons/md';
import { deleteItens } from '~/utils/masterDetail';
import { toast } from 'react-toastify';

export const AbaCondicao: React.FC = () => {
  const {
    condicaoRegister,
    condicaoControl,
    setValueCondicao,
    formStateCondicao,
    diasCondicao,
    setDiasCondicao,
    initCondicao,
    setInitCondicao,
    finalizadoras,
    condicoesOptions,
    condicaoAEditar,
    handleClearCondicao,
    condicoes,
    setCondicoes,
    setCondicaoAEditar,
    masterDetail,
    setMasterDetail,
    saveCondicao,
  } = useFornecedorContext();

  const colunasCondicao: GridColumns = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'finalizadora',
      headerName: 'Finalizadora',
      type: 'string',
      flex: 2,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'dias',
      headerName: 'Condição',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      type: 'number',
      sortable: true,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'condicao',
      headerName: 'Tipo da Condição',
      flex: 2,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: '',
      headerName: 'Ações',
      width: 180,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: (params: { row: any }) => {
        const onEdit = () => {
          const { row } = params;

          setValueCondicao('condicaoFinalizadora', {
            value: row.codFinalizadora,
            label: row.finalizadora,
          });

          setValueCondicao('condicao', {
            value: row.codCondicao,
            label: row.condicao,
          });

          setValueCondicao('numCondicao', row.dias);
          setDiasCondicao(row.dias);
          setCondicaoAEditar({
            uuid: row.uuid,
            cod_seq_pessoa_condicao: row.cod_seq_pessoa_condicao,
          });

          const elm = document.getElementById('collapseThree-fornecedor');
          if (elm) {
            elm.removeAttribute('class');
            elm.classList.add('accordion-collapse', 'collapse', 'show');
          }
        };
        const onDelete = () => {
          const { row } = params;
          handleRemoveCondicao(row.uuid);
        };

        return (
          <>
            <ButtonRow type="button" onClick={onEdit}>
              <GoPencil size={20} style={{ color: '#72ab90' }} /> Editar
            </ButtonRow>
            <ButtonRow type="button" onClick={onDelete}>
              <MdDeleteForever size={20} style={{ color: '#e63c3c' }} /> Excluir
            </ButtonRow>
          </>
        );
      },
    },
  ];

  const handleRemoveCondicao = useCallback(
    async (uuid: any) => {
      const condi: any[] = await deleteItens(
        'condicao',
        uuid,
        masterDetail,
        setMasterDetail,
      );

      setCondicoes(condi);
      handleClearCondicao();
      toast.success('Condição de Pagamento excluída');
    },
    [handleClearCondicao, masterDetail],
  );

  return (
    <Row>
      <Col sm={12}>
        <Separator labelText="Condição de Pagamento" childrenWidth="170px">
          <button
            type="button"
            className="btnLimiteCreditoFornecedor"
            data-bs-toggle="collapse"
            data-bs-target="#collapseThree-fornecedor"
            aria-expanded="false"
            aria-controls="collapseThree"
            onClick={() => {
              setValueCondicao('numCondicao', 30);
              setDiasCondicao(30);
              setValueCondicao('condicao', {
                label: 'DIAS DA DATA - DD',
                value: 2,
              });
            }}
            style={{
              marginTop: '-7px',
            }}
          >
            <AiOutlinePlusCircle
              size={16}
              style={{ marginTop: '-2px', color: 'green' }}
            />{' '}
            Adicionar Condição
          </button>
        </Separator>
      </Col>
      {/* Inicio accordion */}
      <div
        className="accordion"
        id="accordionCondicao"
        style={{ marginBottom: '15px', padding: '0px' }}
      >
        <div
          id="collapseThree-fornecedor"
          className="accordion-collapse collapse"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionCondicao"
        >
          <div className="accordion-body">
            <Row>
              <Col sm={12} md={3} className="finalizadoraContainer">
                <div style={{ marginLeft: '-8px' }}>
                  <InputSelect
                    label="Finalizadora"
                    placeholder="Selecione"
                    name="condicaoFinalizadora"
                    register={condicaoRegister}
                    isError={
                      initCondicao &&
                      !!formStateCondicao.errors.condicaoFinalizadora
                    }
                    control={condicaoControl}
                    options={finalizadoras}
                    changeSelected={(selected) => {
                      setInitCondicao(false);
                      setValueCondicao('condicaoFinalizadora', selected);
                    }}
                  />
                </div>
              </Col>
              <Col
                className="inputDiasContainer"
                sm={12}
                md={1}
                style={{ minWidth: '110px' }}
              >
                <>
                  <InputNumberDias
                    label="Condição"
                    min={0}
                    value={diasCondicao}
                    placeholder=""
                    isDisabled={false}
                    onChange={(val: any) => {
                      setValueCondicao('numCondicao', val);
                      setDiasCondicao(val);
                    }}
                  />
                </>
              </Col>
              <Col sm={12} md={4} className="tipoCondicao">
                <div style={{ marginLeft: '-8px' }}>
                  <InputSelect
                    placeholder="Selecione"
                    name="condicao"
                    label="Tipo da Condição"
                    isError={!!formStateCondicao.errors.condicao}
                    control={condicaoControl}
                    register={condicaoRegister}
                    changeSelected={(selected) =>
                      setValueCondicao('condicao', selected)
                    }
                    options={condicoesOptions}
                  />
                </div>
              </Col>

              <Col
                sm={12}
                md={4}
                className="buttonContainer"
                style={{ paddingRight: '0px' }}
              >
                <div className="actButtonsCredito">
                  <ConfirmButton
                    style={{ height: '42px' }}
                    onClick={() => {
                      saveCondicao();
                    }}
                  >
                    {condicaoAEditar ? 'Editar' : 'Confirmar'}
                  </ConfirmButton>
                  <CancelButton
                    style={{ height: '42px' }}
                    onClick={handleClearCondicao}
                  >
                    Cancelar
                  </CancelButton>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      {/* fim accordion */}
      <Col sm={12} className="dataTable" style={{ width: '100%' }}>
        <Col
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              className="myDataGrid"
              rows={condicoes}
              columns={colunasCondicao}
              autoHeight
              columnBuffer={8}
              sortModel={[
                {
                  field: 'dias',
                  sort: 'asc',
                },
              ]}
              disableColumnFilter
              disableColumnSelector
              disableSelectionOnClick
              disableColumnMenu
              pageSize={4}
              localeText={{
                noRowsLabel: 'Nenhum registro encontrado',
                columnMenuLabel: 'Menu',
                columnMenuFilter: 'Filtrar',
                columnMenuHideColumn: 'Esconder',
                columnMenuUnsort: 'Não ordenar',
                columnMenuSortAsc: 'Ordernar ASC',
                columnMenuSortDesc: 'Ordernar DESC',
                columnMenuShowColumns: 'Mostrar colunas',
              }}
            />
          </div>
        </Col>
      </Col>
    </Row>
  );
};
