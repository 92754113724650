import React, { ChangeEvent } from 'react';
import { Container } from '../../styles';
import { Col, Row } from 'react-bootstrap';
import Separator from '~/components/Separator';
import ToggleDefault from '~/components/ToggleDefault';
import {
  InputDate,
  InputMaskCnpj,
  InputMaskCpf,
  InputMoney,
  InputPercent,
  InputSelect,
  InputText,
} from '~/components/NovosInputs';
import InputRadioButton from '~/components/Inputs/InputRadioButton';
import { useFornecedorContext } from '../../hooks/useFornecedorContext';
import { Fretes, TiposDePessoa } from '../../data';
import { SelectProps } from '../../protocols';

export const AbaCaracteristicas: React.FC = () => {
  const {
    register,
    setValue,
    control,
    clearErrors,
    formState: { errors },
    loadingIcon,
    flg_fabricante,
    tipoPessoa,
    setFlg_fabricante,
    flg_exportador,
    setFlg_exportador,
    flg_regime_st,
    setFlg_regime_st,
    flg_regime_mei,
    setFlg_regime_mei,
    flg_substituto_trib,
    setFlg_substituto_trib,
    flg_optante_simples,
    setFlg_optante_simples,
    tributacoes,
    flg_calc_frete_val_bruto,
    setFlg_calc_frete_val_bruto,
    flg_calc_indeniz_val_bruto,
    setFlg_calc_indeniz_val_bruto,
    flg_calc_desp_ac_val_bruto,
    setFlg_calc_desp_ac_val_bruto,
    flg_calc_bonif_val_bruto,
    setFlg_calc_bonif_val_bruto,
    flg_calc_acresc_val_bruto,
    setFlg_calc_acresc_val_bruto,
    flg_calc_ipi_val_bruto,
    setFlg_calc_ipi_val_bruto,
    flg_rateia_icms_custo,
    setFlg_rateia_icms_custo,
    flg_nao_desc_bc_st,
    setFlg_nao_desc_bc_st,
    flg_nao_transf_icms,
    setFlg_nao_transf_icms,
    flg_nao_transf_pis_cofins,
    setFlg_nao_transf_pis_cofins,
    flg_nao_cred_presumido,
    setFlg_nao_cred_presumido,
    flg_produtor_rural,
    setFlg_produtor_rural,
    tipo_produtor,
    setTipo_produtor,
    setDisabledSearch,
    handleSearchProdutor,
    flg_contabilista,
    setFlg_contabilista,
    flg_suframa,
    setFlg_suframa,
    flg_pauta,
    setFlg_pauta,
    flg_aceita_devol_merc,
    setFlg_aceita_devol_merc,
  } = useFornecedorContext();

  return (
    <Container>
      <Row>
        <Col sm={12}>
          <Separator labelText="Natureza" />
        </Col>
        <Col className="toggleFabricante" sm={3} md={2}>
          <ToggleDefault
            labelText="Fabricante?"
            setChecked={flg_fabricante}
            onSwitch={() => {
              setValue('flg_fabricante', !flg_fabricante);
              setFlg_fabricante(!flg_fabricante);
            }}
          />
        </Col>
        <Col className="toggleExportador" sm={3} md={2}>
          <ToggleDefault
            labelText="Exportador?"
            setChecked={flg_exportador}
            onSwitch={() => {
              setValue('flg_exportador', !flg_exportador);
              setFlg_exportador(!flg_exportador);
            }}
          />
        </Col>

        <Col className="toggleRegimeIe" sm={6} md={3}>
          <ToggleDefault
            labelText="Regime Especial I.E?"
            setChecked={flg_regime_st}
            onSwitch={() => {
              setValue('flg_regime_st', !flg_regime_st);
              setFlg_regime_st(!flg_regime_st);
            }}
          />
        </Col>
        <Col className="toggleRegimeMei" sm={6} md={2}>
          <ToggleDefault
            labelText="Regime Especial MEI?"
            setChecked={flg_regime_mei}
            onSwitch={() => {
              setValue('flg_regime_mei', !flg_regime_mei);
              setFlg_regime_mei(!flg_regime_mei);
            }}
          />
        </Col>
        <Col className="toggleSubstituto" sm={12} md={3}>
          <ToggleDefault
            labelText="Substituto Trib. (REG 53 SINTEGRA)?"
            setChecked={flg_substituto_trib}
            onSwitch={() => {
              setValue('flg_substituto_trib', !flg_substituto_trib);
              setFlg_substituto_trib(!flg_substituto_trib);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={2}>
          <ToggleDefault
            labelText="Optante Simples?"
            setChecked={flg_optante_simples}
            onSwitch={() => {
              setValue('flg_optante_simples', !flg_optante_simples);
              setFlg_optante_simples(!flg_optante_simples);
              setValue('cod_trib_simples', '');
            }}
          />
        </Col>
        <Col sm={12} md={5}>
          <InputSelect
            label="ICMS"
            maxLength={50}
            placeholder="Selecione"
            name="cod_trib_simples"
            disabled={!flg_optante_simples}
            register={register}
            isError={!!errors.cod_trib_simples}
            control={control}
            options={tributacoes}
            changeSelected={(selected: SelectProps) => {
              setValue('cod_trib_simples', selected);
            }}
          />
        </Col>
        <Col sm={12} md={5}>
          <InputSelect
            label="Tipo de Frete"
            maxLength={50}
            placeholder="Selecione"
            name="tipo_frete"
            register={register}
            isError={!!errors.tipo_frete}
            control={control}
            options={Fretes}
            changeSelected={(selected) => {
              setValue('tipo_frete', selected);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Separator labelText="Considera na Base de Cálculo ICMS O.P" />
        </Col>
        <Col className="toggleFrete" sm={4} md={2}>
          <ToggleDefault
            labelText="Frete?"
            setChecked={flg_calc_frete_val_bruto}
            onSwitch={() => {
              setValue('flg_calc_frete_val_bruto', !flg_calc_frete_val_bruto);
              setFlg_calc_frete_val_bruto(!flg_calc_frete_val_bruto);
            }}
          />
        </Col>
        <Col className="toggleIndenizacao" sm={4} md={2}>
          <ToggleDefault
            labelText="Indenização?"
            setChecked={flg_calc_indeniz_val_bruto}
            onSwitch={() => {
              setValue(
                'flg_calc_indeniz_val_bruto',
                !flg_calc_indeniz_val_bruto,
              );
              setFlg_calc_indeniz_val_bruto(!flg_calc_indeniz_val_bruto);
            }}
          />
        </Col>
        <Col className="toggleDespesa" sm={4} md={2}>
          <ToggleDefault
            labelText="Despesas Acessórias?"
            setChecked={flg_calc_desp_ac_val_bruto}
            onSwitch={() => {
              setValue(
                'flg_calc_desp_ac_val_bruto',
                !flg_calc_desp_ac_val_bruto,
              );
              setFlg_calc_desp_ac_val_bruto(!flg_calc_desp_ac_val_bruto);
            }}
          />
        </Col>
        <Col className="toggleBonificacao" sm={4} md={2}>
          <ToggleDefault
            labelText="Bonificação?"
            setChecked={flg_calc_bonif_val_bruto}
            onSwitch={() => {
              setValue('flg_calc_bonif_val_bruto', !flg_calc_bonif_val_bruto);
              setFlg_calc_bonif_val_bruto(!flg_calc_bonif_val_bruto);
            }}
          />
        </Col>
        <Col className="toggleAcrescimo" sm={4} md={2}>
          <ToggleDefault
            labelText="Acréscimo?"
            setChecked={flg_calc_acresc_val_bruto}
            onSwitch={() => {
              setValue('flg_calc_acresc_val_bruto', !flg_calc_acresc_val_bruto);
              setFlg_calc_acresc_val_bruto(!flg_calc_acresc_val_bruto);
            }}
          />
        </Col>
        <Col className="toggleIpi" sm={4} md={2}>
          <ToggleDefault
            labelText="Valor IPI?"
            setChecked={flg_calc_ipi_val_bruto}
            onSwitch={() => {
              setValue('flg_calc_ipi_val_bruto', !flg_calc_ipi_val_bruto);
              setFlg_calc_ipi_val_bruto(!flg_calc_ipi_val_bruto);
            }}
          />
        </Col>
        <Col sm={12}>
          <Separator labelText="Substituição" />
        </Col>
        <Col sm={12} md={6}>
          <ToggleDefault
            labelText="Rateia ICMS ST Nos itens ST (CUSTO)?"
            setChecked={flg_rateia_icms_custo}
            onSwitch={() => {
              setValue('flg_rateia_icms_custo', !flg_rateia_icms_custo);
              setFlg_rateia_icms_custo(!flg_rateia_icms_custo);
            }}
          />
        </Col>
        <Col sm={12} md={6}>
          <ToggleDefault
            labelText="Não Considera Desc. e/ou Indeniz. na BC ST?"
            setChecked={flg_nao_desc_bc_st}
            onSwitch={() => {
              setValue('flg_nao_desc_bc_st', !flg_nao_desc_bc_st);
              setFlg_nao_desc_bc_st(!flg_nao_desc_bc_st);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Separator labelText="NÃO Transfere Crédito de" />
        </Col>
        <Col sm={3} md={2}>
          <ToggleDefault
            labelText="ICMS?"
            setChecked={flg_nao_transf_icms}
            onSwitch={() => {
              setValue('flg_nao_transf_icms', !flg_nao_transf_icms);
              setFlg_nao_transf_icms(!flg_nao_transf_icms);
            }}
          />
        </Col>
        <Col sm={3} md={2}>
          <ToggleDefault
            labelText="PIS/Cofins?"
            setChecked={flg_nao_transf_pis_cofins}
            onSwitch={() => {
              setValue('flg_nao_transf_pis_cofins', !flg_nao_transf_pis_cofins);
              setFlg_nao_transf_pis_cofins(!flg_nao_transf_pis_cofins);
            }}
          />
        </Col>
        <Col sm={12}>
          <Separator labelText="PIS/Cofins" />
        </Col>
        <Col sm={12} md={12}>
          <ToggleDefault
            labelText="Não Considera Alíquota Para Crédito Presumido?"
            setChecked={flg_nao_cred_presumido}
            onSwitch={() => {
              setValue('flg_nao_cred_presumido', !flg_nao_cred_presumido);
              setFlg_nao_cred_presumido(!flg_nao_cred_presumido);
            }}
          />
        </Col>
        <Col sm={12}>
          <Separator labelText="Produtor Rural" />
        </Col>
        <Col className="ToggleresponsiveClass50" sm={3} md={2}>
          <ToggleDefault
            labelText="Produtor Rural?"
            setChecked={flg_produtor_rural}
            onSwitch={() => {
              setValue('flg_produtor_rural', !flg_produtor_rural);
              setFlg_produtor_rural(!flg_produtor_rural);
              setTipo_produtor(0);
              setValue('tipo_produtor', 0);
              setValue('produtor_rural_num_cpf', '');
              setValue('produtor_rural_num_cnpj', '');
            }}
          />
        </Col>
        <Col className="containerTipoPessoa" sm={12} md={3}>
          <div>
            <InputRadioButton
              name="tipo_produtor"
              value={tipo_produtor}
              label="Tipo de Produtor"
              options={TiposDePessoa}
              isDisabled={!flg_produtor_rural}
              isDisabledColor={!flg_produtor_rural}
              onChange={(val) => {
                setTipo_produtor(Number(val));
                setValue('tipo_produtor', Number(val));
                setDisabledSearch(false);
                if (val === 0) {
                  setValue('produtor_rural_num_cnpj', '');
                  clearErrors('produtor_rural_num_cpf');
                } else {
                  setValue('produtor_rural_num_cpf', '');
                  clearErrors('produtor_rural_num_cnpj');
                }
              }}
            />
          </div>
        </Col>
        <Col className="containerDocument" sm={12} md={3}>
          <div
            className="input-container"
            style={{ marginTop: '0.75rem' }}
            onClick={() => setDisabledSearch(false)}
          >
            {tipo_produtor === 0 && (
              <InputMaskCpf
                label="CPF"
                name="produtor_rural_num_cpf"
                hasButton
                clickedButton={handleSearchProdutor}
                loadingIcon={loadingIcon === 'Produtor'}
                isError={!!errors.produtor_rural_num_cpf}
                isDisabled={!flg_produtor_rural}
                control={control}
                register={register}
              />
            )}
            {tipo_produtor === 1 && (
              <InputMaskCnpj
                label="CNPJ"
                name="produtor_rural_num_cnpj"
                hasButton
                clickedButton={handleSearchProdutor}
                loadingIcon={loadingIcon === 'Produtor'}
                isError={!!errors.produtor_rural_num_cnpj}
                isDisabled={!flg_produtor_rural}
                control={control}
              />
            )}
          </div>
        </Col>
        <Col sm={12}>
          <Separator labelText="Contabilista" />
        </Col>
        <Col sm={12} md={2}>
          <ToggleDefault
            labelText="Contabilista?"
            setChecked={flg_contabilista}
            disabled={tipoPessoa === 1}
            onSwitch={() => {
              setValue('flg_contabilista', !flg_contabilista);
              setFlg_contabilista(!flg_contabilista);
              setValue('num_crc', '');
              setValue('num_seq_crc', '');
              setValue('dta_validade_crc', null);
              clearErrors('num_crc');
              clearErrors('num_seq_crc');
              clearErrors('dta_validade_crc');
            }}
          />
        </Col>
        <Col sm={12} md={3}>
          <InputText
            label="CRC"
            name="num_crc"
            placeholder="Digite seu CRC"
            maxLength={19}
            register={register}
            disabled={!flg_contabilista}
            control={control}
            isError={!!errors.num_crc}
          />
        </Col>
        <Col sm={12} md={3}>
          <InputText
            label="Sequencial CRC"
            name="num_seq_crc"
            placeholder="Sequencial do seu CRC"
            maxLength={25}
            min={0}
            max={25}
            control={control}
            register={register}
            disabled={!flg_contabilista}
            isError={!!errors.num_seq_crc}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setValue('num_seq_crc', e.target.value);
            }}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputDate
            label="Vencimento do CRC"
            placeholder=""
            name="dta_validade_crc"
            register={register}
            isDisabled={!flg_contabilista}
            isError={!!errors.dta_validade_crc}
            control={control}
          />
        </Col>
        <Col sm={12}>
          <Separator labelText="Outras Opções" />
        </Col>
        <Col sm={12} md={7}>
          <ToggleDefault
            labelText="(SUFRAMA) Considerar Desconto Para Crédito Incentivado?"
            setChecked={flg_suframa}
            onSwitch={() => {
              setValue('flg_suframa', !flg_suframa);
              setFlg_suframa(!flg_suframa);
            }}
          />
        </Col>
        <Col sm={12} md={5}>
          <ToggleDefault
            labelText="Considerar Pauta dos Produtos?"
            setChecked={flg_pauta}
            onSwitch={() => {
              setValue('flg_pauta', !flg_pauta);
              setFlg_pauta(!flg_pauta);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={2}>
          <ToggleDefault
            labelText="Aceita Troca?"
            setChecked={flg_aceita_devol_merc}
            onSwitch={() => {
              setValue('flg_aceita_devol_merc', !flg_aceita_devol_merc);
              setValue('val_minimo_troca', '0,00');
              setFlg_aceita_devol_merc(!flg_aceita_devol_merc);
            }}
          />
        </Col>
        <Col className="valorMinimoContainer" sm={12} md={2}>
          <InputMoney
            label="Valor Mínimo"
            min={0.01}
            decimals={2}
            disabled={!flg_aceita_devol_merc}
            name="val_minimo_troca"
            register={register}
            isError={!!errors.val_minimo_troca}
            control={control}
            showIcon
            placeholder="0,00"
          />
        </Col>
        <Col className="responsiveClass" sm={12} md={2}>
          <InputPercent
            label="Desconto"
            min={0}
            max={100}
            name="per_desconto"
            register={register}
            control={control}
            isError={!!errors.per_desconto}
            showIcon
          />
        </Col>
        <Col className="responsiveClass" sm={12} md={2}>
          <InputPercent
            label="Frete"
            min={0}
            max={100}
            name="per_frete"
            register={register}
            control={control}
            isError={!!errors.per_frete}
            showIcon
          />
        </Col>
        <Col className="responsiveClass" sm={12} md={2}>
          <InputPercent
            label="Acordo Financeiro"
            min={0}
            max={100}
            control={control}
            name="per_acordo_fin"
            register={register}
            isError={!!errors.per_acordo_fin}
            showIcon
          />
        </Col>
      </Row>
    </Container>
  );
};
