import React from 'react';
import ToggleDefault from '~/components/ToggleDefault';

import { ButtonRow, Container } from '../../styles';
import { Row, Col } from 'react-bootstrap';
import { useFornecedorContext } from '../../hooks/useFornecedorContext';
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import { GoPencil } from 'react-icons/go';
import { MdDeleteForever } from 'react-icons/md';
import { InputSelect } from '~/components/NovosInputs';
import { CancelButton, ConfirmButton } from '~/components/Buttons';

export const AbaCompra: React.FC = () => {
  const {
    register,
    setValue,
    control,
    clearErrors,
    flg_beneficiario,
    setFlg_beneficiario,
    retencoesOptions,
    retencoesRows,
    saveRetencao,
    editRetencao,
    deleteRetencao,
  } = useFornecedorContext();

  const colunasCompra: GridColumns = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'cod_retencao',
      headerName: 'Retenção',
      hide: true,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'descricao',
      headerName: 'Retenção',
      flex: 2,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'des_conta_contabil',
      headerName: 'Conta Contábil',
      flex: 2,
      headerAlign: 'left',
      align: 'left',
      sortable: true,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: '',
      headerName: 'Ações',
      width: 180,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: (params: { row: any }) => {
        const { row } = params;

        return (
          <>
            <ButtonRow type="button" onClick={() => editRetencao(row)}>
              <GoPencil size={20} style={{ color: '#72ab90' }} /> Editar
            </ButtonRow>
            <ButtonRow type="button" onClick={() => deleteRetencao(row.uuid)}>
              <MdDeleteForever size={20} style={{ color: '#e63c3c' }} /> Excluir
            </ButtonRow>
          </>
        );
      },
    },
  ];

  return (
    <Container>
      <Row>
        <Col className="toggleBeneficiario" sm={3} md={2}>
          <ToggleDefault
            labelText="Beneficiário da Retenção?"
            setChecked={flg_beneficiario}
            disabled={retencoesRows.length > 0}
            onSwitch={() => {
              setValue('flg_beneficiario', !flg_beneficiario);
              setFlg_beneficiario(!flg_beneficiario);
            }}
          />
        </Col>
        {/* Inicio accordion */}
        <div
          className="accordion"
          id="accordionCompra"
          style={{ marginBottom: '15px', padding: '0px' }}
        >
          <div
            id="collapseThree-compra"
            className={`accordion-collapse collapse ${
              flg_beneficiario ? 'show' : ''
            }`}
            aria-labelledby="headingOne"
            aria-expanded={flg_beneficiario}
            data-bs-parent="#accordionCompra"
          >
            <div className="accordion-body">
              <Row>
                <Col sm={12} md={8} className="finalizadoraContainer">
                  <div style={{ marginLeft: '-8px' }}>
                    <InputSelect
                      label="Retenção"
                      placeholder="Selecione"
                      name="retencao"
                      register={register}
                      isError={false}
                      control={control}
                      options={retencoesOptions}
                      changeSelected={(selected) => {
                        clearErrors('retencao');
                        setValue('retencao', selected);
                      }}
                    />
                  </div>
                </Col>

                <Col
                  sm={12}
                  md={4}
                  className="buttonContainer"
                  style={{ paddingRight: '0px' }}
                >
                  <div className="actButtonsCredito">
                    <ConfirmButton
                      style={{ height: '42px' }}
                      onClick={() => {
                        saveRetencao();
                      }}
                    >
                      Confirmar
                    </ConfirmButton>
                    <CancelButton
                      style={{ height: '42px' }}
                      onClick={() => setValue('retencao', '')}
                    >
                      Cancelar
                    </CancelButton>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        {/* fim accordion */}
        <Col sm={12} className="dataTable" style={{ width: '100%' }}>
          <Col
            style={{
              display: 'flex',
              height: '100%',
              width: '100%',
            }}
          >
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                className="myDataGrid"
                rows={retencoesRows}
                columns={colunasCompra}
                autoHeight
                columnBuffer={8}
                getRowId={(row) => row.cod_retencao}
                sortModel={[
                  {
                    field: 'cod_retencao',
                    sort: 'asc',
                  },
                ]}
                disableColumnFilter
                disableColumnSelector
                disableSelectionOnClick
                disableColumnMenu
                pageSize={4}
                localeText={{
                  noRowsLabel: 'Nenhum registro encontrado',
                  columnMenuLabel: 'Menu',
                  columnMenuFilter: 'Filtrar',
                  columnMenuHideColumn: 'Esconder',
                  columnMenuUnsort: 'Não ordenar',
                  columnMenuSortAsc: 'Ordernar ASC',
                  columnMenuSortDesc: 'Ordernar DESC',
                  columnMenuShowColumns: 'Mostrar colunas',
                }}
              />
            </div>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};
